var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.$vuetify.breakpoint.mobile
      ? _c(
          "div",
          { staticClass: "row" },
          [
            _c("v-spacer"),
            _vm.isCustomer && !_vm.isProxy
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-md-6 col-xl-4 d-flex justify-content-center",
                  },
                  [
                    _c(
                      "b-jumbotron",
                      {
                        staticClass: "text-center",
                        attrs: {
                          "bg-variant": "success",
                          "text-variant": "white",
                        },
                      },
                      [
                        _c("span", { staticStyle: { "font-size": "32px" } }, [
                          _vm._v("Hoş Geldiniz"),
                        ]),
                        _c("hr", { staticClass: "my-3" }),
                        !_vm.$vuetify.breakpoint.mobile
                          ? _c("span", { staticClass: "font-size-h4" }, [
                              _vm._v(
                                " Öğrencilerinizin hesaplarına geçiş yapmak için menüde isminizin yazdığı butona tıklayabilirsiniz. "
                              ),
                            ])
                          : _c("span", { staticClass: "font-size-h4" }, [
                              _vm._v(
                                " Öğrencilerinizin hesaplarına geçiş yapmak için alt menüde öğrenciler ikonuna tıklayabilirsiniz. "
                              ),
                            ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c("v-spacer"),
          ],
          1
        )
      : _vm._e(),
    (!_vm.isCustomer || _vm.isProxy) &&
    _vm.user != null &&
    _vm.user.IsActiveAcademicYear
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm align-self-center" },
            [
              _c(
                "b-alert",
                {
                  staticClass: "mt-5",
                  attrs: { show: "", variant: "primary" },
                },
                [_vm._v("HOŞ GELDİN " + _vm._s(_vm.user.FirstName))]
              ),
              _c("StudentExamGoal"),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.user != null && !_vm.user.IsActiveAcademicYear
      ? _c(
          "div",
          { staticClass: "row" },
          [
            _c("v-spacer"),
            _c(
              "div",
              {
                staticClass:
                  "col-md-6 col-xl-4 col-sm-12 align-self-center pb-0 d-flex justify-content-center",
              },
              [
                _c(
                  "b-alert",
                  {
                    staticClass: "text-center",
                    attrs: { show: "", variant: "danger" },
                  },
                  [
                    _vm._v(" Aktif akademik yılda değilsiniz. "),
                    _c("br"),
                    _vm._v(
                      " Mevcut akademik yıla geçmek için menüden yıl seçimi yapınız. "
                    ),
                  ]
                ),
              ],
              1
            ),
            _c("v-spacer"),
          ],
          1
        )
      : _vm._e(),
    !_vm.isCustomer || _vm.isProxy
      ? _c("div", { staticClass: "row mt-1" }, [
          _c(
            "div",
            { staticClass: "col-sm align-self-center pt-1" },
            [
              _c("DashboardDateWidget", {
                on: {
                  updateDates: function ($event) {
                    return _vm.datesUpdated($event)
                  },
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    (!_vm.isCustomer || _vm.isProxy) &&
    _vm.user &&
    !_vm.user.IsElementaryStudent
      ? _c("div", { staticClass: "row mt-1" }, [
          _c(
            "div",
            { staticClass: "col-sm align-self-center" },
            [
              _vm.dates.length > 0
                ? _c("HomeworksWidget", {
                    key: _vm.compKey,
                    attrs: { dates: _vm.dates },
                  })
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
    !_vm.isCustomer || _vm.isProxy
      ? _c("div", { staticClass: "row mt-1" }, [
          _c(
            "div",
            { staticClass: "col-sm align-self-center" },
            [
              _vm.dates.length > 0
                ? _c("ManualHomeworksWidget", {
                    key: _vm.compKey,
                    attrs: { dates: _vm.dates },
                  })
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
    !_vm.isCustomer || _vm.isProxy
      ? _c("div", { staticClass: "row mt-3" }, [
          _c(
            "div",
            { staticClass: "col-sm align-self-center" },
            [
              _vm.dates.length > 0
                ? _c("Calendar", {
                    key: _vm.compKey,
                    attrs: { dates: _vm.dates },
                  })
                : _vm._e(),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.examSessions.length && (!_vm.isCustomer || _vm.isProxy)
      ? _c("div", { staticClass: "row mt-5" }, [
          _c(
            "div",
            { staticClass: "col-sm align-self-center" },
            [
              _c("ExamSessionsWidget", {
                ref: "ExamSessionsWidget",
                attrs: {
                  examSessions: _vm.examSessions,
                  loading: _vm.loading,
                  title: "Yaklaşan ve Aktif Sınav Oturumlarınız",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm.isCustomer && !_vm.isProxy
      ? _c(
          "div",
          { staticClass: "row" },
          [_c("Panel", { attrs: { "is-dashboard": true } })],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }