<template>
  <div v-if="hasExamGoal">
    <b-overlay :show="loading" rounded="lg" opacity="1">
      <div v-if="item">
        <v-card
          @click="
            editItem(item)
            editedIndex = 1
          "
          class="shine text-center"
          flat
          color="deep-purple lighten-4"
          elevation="24"
          outlined
        >
          <v-card-title style="background-color: #8950fc" class="text-center text-white">
            <v-spacer></v-spacer>
            HEDEF
            <v-spacer></v-spacer>
            <v-icon x-small>mdi-pencil</v-icon>
          </v-card-title>
          <h2 class="mt-2 text-black">
            {{ item.Name }}
          </h2>
          <v-card-actions></v-card-actions>
        </v-card>
      </div>
      <div v-else>
        <v-btn
          @click="
            editItem(item)
            editedIndex = -1
          "
          block
          dark
          class="shine"
          color="success"
          >Yeni Hedef Ekle</v-btn
        >
      </div>
    </b-overlay>
    <v-dialog
      content-class="my-custom-dialog"
      transition="dialog-top-transition"
      v-model="dialog"
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.Name"
                type="text"
                label="Hedef adı"
                hint="(Boğaziçi Üniversitesi)"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.MinPoint"
                type="number"
                label="Minimum Puan"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="editedItem.MaxPoint"
                type="number"
                label="Maximum Puan"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close"> İptal Et </v-btn>
          <v-btn color="blue darken-1" text @click="save"> Kaydet </v-btn>
        </v-card-actions>
      </v-card>

      <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor" rounded="pill">
        {{ snackbarText }}
      </v-snackbar>
    </v-dialog>
  </div>
</template>
<script>
import ApiService from '@/core/services/api.service.js'

export default {
  name: 'student-exam-goal',
  data() {
    return {
      item: null,
      loading: false,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      dialog: false,
      hasExamGoal: false
    }
  },
  mounted() {
    this.getDataFromApi()
    this.getHasExamGoal()
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Hedef Ekle' : 'Hedef Düzenle'
    }
  },
  methods: {
    getHasExamGoal() {
      ApiService.setHeader()
      ApiService.post('api/StudentExamGoal/HasExamGoal', {})
        .then(({ data }) => {
          this.hasExamGoal = data
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    },
    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.get('api/StudentExamGoal/' + 0)
        .then((data) => {
          this.item = data.data
          this.loading = false
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    },
    editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      ApiService.setHeader()
      ApiService.put('api/StudentExamGoal', this.editedItem)
        .then(() => {
          this.close()
          this.getDataFromApi()
        })
        .catch(({ response }) => {
          ApiService.showError(response)
        })
    }
  }
}
</script>
<style>
body.shine {
  margin: 0;
  overflow: hidden;
}

/* blue bar */
div.shine {
  width: 100%;
  background: #59cce0;
  z-index: 0;
  position: relative;
}

/* text */
span.shine,
a.shine {
  color: #fff;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: inline-block;
  font-size: 30px;
  font-family: 'open sans', sans-serif;
  letter-spacing: -1px;
  font-weight: bold;
}
span.shine {
  top: 50%;
}
a.shine {
  color: #bbb;
  font-size: 11px;
  bottom: 20px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
}
/* Shine */
div.shine:after {
  content: '';
  top: 0;
  transform: translateX(100%);
  width: 100%;
  height: 220px;
  position: absolute;
  z-index: 1;
  animation: slide 1s infinite 3s;

  /* 
  CSS Gradient - complete browser support from http://www.colorzilla.com/gradient-editor/ 
  */
  background: -moz-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(50%, rgba(255, 255, 255, 0.8)),
    color-stop(99%, rgba(128, 186, 232, 0)),
    color-stop(100%, rgba(125, 185, 232, 0))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(128, 186, 232, 0) 99%,
    rgba(125, 185, 232, 0) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
}

/* animation */

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>
